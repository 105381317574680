import React from "react";
import styled from "styled-components";
import Layout from "../components/Layout";
import Seo from "../components/Seo";
import { Link } from "gatsby";
import { BsCaretLeftFill } from "react-icons/bs";

const NotFoundPageStyles = styled.main`
  grid-column: 2 / span 12;
  grid-row: 1 / span 2;
  height: 100vh;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .not-found {
    color: var(--color-black);
    font-family: "OakesGrotesk", Arial, sans-serif;
    font-weight: 600;
    font-size: var(--font-hero-size);
    line-height: var(--font-hero-line-height);
  }

  .btn {
    svg {
      margin-bottom: -0.15em;
    }
  }
`;

const NotFoundPage = () => {
  return (
    <Layout>
      <Seo title="404" />

      <NotFoundPageStyles>
        <p className="not-found">404</p>
        <h1>Page not found!</h1>
        <Link to="/" className="btn btn-primary">
          <BsCaretLeftFill /> Go Back
        </Link>
      </NotFoundPageStyles>
    </Layout>
  );
};

export default NotFoundPage;
